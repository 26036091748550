import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Copyright() {
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={12}>
                <p>2023© Copyright <strong>LEADSBRIDGE</strong>. All Rights Reserved</p>
            </Col>
        </Row>
    </Container>
  )
}

export default Copyright