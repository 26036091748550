import { React } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaSkype } from "@react-icons/all-files/fa/FaSkype";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className='footer-link'>
        <Container>
          <Row>
              <Col md={3}>
                  <h3>LEADS<span className='theme-color'>BRIDGE</span></h3>
                  <p className='text-white footer-desc'>
                  1309 Coffeen Avenue STE 14043 Sheridan Wyoming 82801
                  </p>
                  <ul className='info'>
                    <li><strong>Phone</strong>: <a href="tel:+18192604785">+1 5102548189</a></li>
                    <li><strong>Email</strong>: <a href="mailto:support@leadsbridge.net">support@leadsbridge.net</a></li>
                  </ul>
              </Col>
              <Col md={3}>
                  <h6>
                    Useful Links
                  </h6>
                  <ul className='link'>
                    <li><Link to="/#home"><FaChevronRight className='footer-sublink'/> Home</Link></li>
                    <li><Link to="/#about"><FaChevronRight className='footer-sublink'/> About us</Link></li>
                    <li><Link to="/#partners"><FaChevronRight className='footer-sublink'/> Partners</Link></li>
                    <li><Link to="/#why-choose"><FaChevronRight className='footer-sublink'/> Why Choose Us</Link></li>
                    <li><Link to="/#contact"><FaChevronRight className='footer-sublink'/> Contact Us</Link></li>
                  </ul>
              </Col>
              <Col md={6}>
                  <h6>
                    Our Social Networks
                  </h6>
                  <ListGroup className=''>
                      <ListGroup.Item className='theme-color-2'>
                          <span className='sm-icons'><FaTwitter/></span>
                          <span className='sm-icons'><FaFacebookF /></span>
                          <span className='sm-icons'><FaInstagram /></span>
                          <span className='sm-icons'><FaSkype /></span>
                          <span className='sm-icons'><FaLinkedinIn /></span>
                      </ListGroup.Item>
                  </ListGroup>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  )
}

export default Footer