import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Publisher() {
  return (
    <Container>
        <Row className='justify-content-md-center align-items-sm-center'>
            <Col md={6}>
                <p>
                    <img src='./assets/img/Publisher-platform.png' alt="Publisher Platform"/>
                </p>
            </Col>
            <Col md={6}>
                <h2>Publisher <span className='theme-color'>platform</span></h2>
                <p>
                It is a platform where premium publishers - our partners - are located. <span className='theme-color'>LEADSBRIDGE</span> allows the end-user to monetize online traffic furthermore through the publishing platform in the form of RTB, Native Ads, Search Queries and other Ad formats. If you have your own website, the best CPC is available on the <span className='theme-color'>LEADSBRIDGE</span> advertising platform, which will connect you with the best advertisers worldwide. How can you use the platform to monetize your traffic? We can help you there! Place our customizable widgets of the best ads and brands on your website and be our top partner with the highest revenue! No need to wait for reports, analytics are available in real time!<br/><br/>
                    <strong>Increase revenue by displaying intent-centric content across top-converting all formats, for various device types, and in all GEOs.</strong>
                </p>
            </Col>
        </Row>
    </Container>
  )
}

export default Publisher