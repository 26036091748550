import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function About() {
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <Container>
      <Row className='about-dmm'>
        <Col lg={6} md={12} data-aos="fade-right" data-aos-duration="500">
            <img src="./assets/img/Marketing-icon-1.svg" alt="Worldmap" />
        </Col>
        <Col lg={6} md={12}>
            <h2 className='' data-aos="fade-left" data-aos-duration="500">
              About <span className='theme-color'>leadsbridge</span>
            </h2>
            <p data-aos="fade-left" data-aos-duration="500">
            <span className='theme-color site-name'>LEADSBRIDGE</span> is a company that helps developers and extension owners acquire new users and monetize browser extensions and their digital products. We work with a variety of clients, including extension developers, publishers, and platforms. Our goal is to help our clients reach their target audiences and maximize their revenue potential.
            </p>
            <ul className='item-list-cs'>
              <li data-aos="fade-up" data-aos-duration="500">
                <Row>
                  <Col md={2} sm={12}>
                    <div className='icon-section'><img src='./assets/img/Advertising-Platform.png' alt="Advertising Platform"/></div>
                  </Col>
                  <Col md={10} sm={12}>
                    <h3>Advertising Platform</h3>
                    <p>We have developed a platform that takes care of all the needs of your brand or product, so that it is always highly ranked and profitable, through innovative software   technology. It’s the only advertising platform ...<br /><br /><Button variant="outline-primary"  data-href="#advertising" className='btn-cs'  size="sm" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>READ MORE</Button></p>
                  </Col>
                </Row>
              </li>
              <li data-aos="fade-up" data-aos-duration="500">
                <Row>
                  <Col md={2} sm={12}>
                    <div className='icon-section'><img src='./assets/img/Publisher-platform.png' alt="Publisher platform"/></div>
                  </Col>
                  <Col md={10} sm={12}>
                    <h3>Publisher platform</h3>
                    <p>It is a platform where premium publishers - our partners - are located. <span className='theme-color site-name'>LEADSBRIDGE</span> allows the end-user to monetize online traffic furthermore through the publishing platform ...<br /><br /><Button variant="outline-primary"  data-href="#publisher" className='btn-cs'  size="sm" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>READ MORE</Button></p>
                  </Col>
                </Row>
              </li>
            </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default About