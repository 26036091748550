import { React, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Partners() {
    const [show, setShow] = useState([]);
    useEffect(() => {
        setShow(['Airfind.png', 'Ask.svg', 'Bing.svg', "CBS.svg", 'CodeFuel.svg', 'linkvertise.png', 'perion.svg', 'system1.svg', 'taboola.svg', 'Tempest.svg', 'TikTok.png', 'yahoo.svg']);
    }, []);
    return (
        <Container>

            <Row  className="partners-section justify-content-lg-center post-grid">
                <Col md={12}  data-aos="fade-in" data-aos-duration="500">
                    <h2 className='text-center offers ser'>
                        Who We <span className='theme-color'>Work</span> With?
                    </h2>
                    
                    <p className='text-center'>Sample of our awesome clients we've had the pleasure to work with!</p>
                </Col>
                {
                    show.map((res, id)=>(
                        <Col xs={6} sm={6} md={6} lg={2} key={id} className='p-3'  data-aos="fade-up" data-aos-duration={`${id}00`}>
                            <Card style={{width:"100%", height:"100%"}}>
                                <div className='card-img-extra-paddng text-center'><Card.Img variant="top" src={`assets/img/${res}`} /></div>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Partners