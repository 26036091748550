import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

function WhyChoose() {
  return (
    <Container>
        <Row>
            <Col md={4}>
                <h2>Why Choose <span className='theme-color'>LEADSBRIDGE</span></h2>
                <p>
                    Whether it's through the best technology in the industry or unmatched customer support, we take care of your success.
                </p>
            </Col>
            <Col md={4}>
                <ul className='item-list-cs'>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>Brand-Safe Advertising</h3>
                            <p>100% secure ads from our publishing partners</p>
                        </Col>
                        </Row>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>In-house Platform</h3>
                            <p>Robust technology behind every decision made</p>
                        </Col>
                        </Row>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>Cloud Infrastructure</h3>
                            <p>Efficiency, high speed, and data security guaranteed</p>
                        </Col>
                        </Row>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>Real-time Reporting</h3>
                            <p>Comprehensive stats that enable calculated and impactful decisions</p>
                        </Col>
                        </Row>
                    </li>
                </ul>
            </Col>
            <Col md={4}>
                <ul className='item-list-cs'>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>Great Customer Experience</h3>
                            <p>Managers who go above and beyond to meet your needs</p>
                        </Col>
                        </Row>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>User-friendly Interface</h3>
                            <p>Intuitive dashboard that facilitates easy navigation through important data</p>
                        </Col>
                        </Row>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>Global Coverage</h3>
                            <p>Digitally active in more than 220 countries</p>
                        </Col>
                        </Row>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="500">
                        <Row>
                        <Col md={2} sm={12}>
                            <div className='icon-section'><FaCheckCircle className='icon-cs'/></div>
                        </Col>
                        <Col md={10} sm={12}>
                            <h3>Automation Rules</h3>
                            <p>Smart campaign setup to eliminate unwanted budget leaks</p>
                        </Col>
                        </Row>
                    </li>
                </ul>
            </Col>
        </Row>
    </Container>
  )
}

export default WhyChoose