import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Advertising() {
  return (
    <Container>
        <Row className='justify-content-md-center align-items-sm-center'>
            <Col md={6}>
                <p>
                    <img src='./assets/img/Advertising-Platform.png' alt="Advertising Platform"/>
                </p>
            </Col>
            <Col md={6}>
                <h2>Advertiser <span className='theme-color'>platform</span></h2>
                <p>
                    We have developed a platform that takes care of all the needs of your brand or product, so that it is always highly ranked and profitable, through innovative software technology. It’s the only advertising platform where targeting is possible through any vertical you can imagine – GEO, Device type, Operating system...<br/><br/>

                    Intuitive dashboards with detailed graphics, comprehensive stats, various possibilities, and much more to simplify performance tracking, enhance campaign optimization, and grow publisher monetization.<br/><br/>
                    <strong>Deliver your brand message through our directory of premium publishing sites, targeting the right users at the right time</strong>
                </p>
            </Col>
        </Row>
    </Container>
  )
}

export default Advertising