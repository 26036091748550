import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";

function Header({ activeSection }) {
  return (
    <>
        {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary" collapseOnSelect>
          <Container>
            <Navbar.Brand href="/">leads<span className='theme-color'>bridge</span></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                leads<span className='theme-color'>bridge</span>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3 me-auto align-items-center">
                  <Nav.Link as={Link} eventKey="1" to="/#about" className={activeSection === 1 ? "theme-color item--active" : "theme-color"}>About Us</Nav.Link>
                  <Nav.Link as={Link} eventKey="2" to="/#partners" className={activeSection === 2 ? "theme-color item--active" : "theme-color"}>PARTNERS</Nav.Link>
                  <Nav.Link as={Link} eventKey="3" to="/#why-choose" className={activeSection === 3 ? "theme-color item--active" : "theme-color"}>Why Choose us</Nav.Link>
                  <Nav.Link as={Link} eventKey="4" to="/#contact" className={activeSection === 4 ? "theme-color item--active" : "theme-color"}>Contact Us</Nav.Link>
                </Nav>
                
                <Nav className='button-navbar get-started'>
                  <Nav.Link as={Link} eventKey="5" to="/#contact" className={activeSection === 3 ? "theme-color item--active" : "theme-color"}>CONTACT US</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header